<template>
    <div v-if="ready" class="fill-height">
        <subpage-top-bar>Статистика посещений</subpage-top-bar>
      
        <v-container fluid class="fill-height pt-0 pb-0">
            <v-row
                v-if="!errors"
                justify="start"
                align="stretch"
                class="fill-height overflow-hidden"
            >
                <v-col v-if="ready" :cols="12" class="fill-height overflow-auto">
                    <div class="d-flex flex-row justify-start align-center">
                        <!-- From -->
                        <div class="mr-2">
                            <v-datetime-picker 
                                label="От" 
                                v-model="filter.from"
                                :timePickerProps="{
                                    format: '24h'
                                }"
                                okText="Выбрать"
                                clearText="Очистить"
                            >
                                <template v-slot:dateIcon>
                                    <v-icon>mdi-calendar</v-icon>
                                </template>
                                <template v-slot:timeIcon>
                                    <v-icon>mdi-clock</v-icon>
                                </template>
                            </v-datetime-picker>
                        </div>
                        <div class="mr-2">
                            <!-- To -->
                            <v-datetime-picker 
                                label="До" 
                                v-model="filter.to"
                                :timePickerProps="{
                                    format: '24h'
                                }"
                                okText="Выбрать"
                                clearText="Очистить"
                            >
                                <template v-slot:dateIcon>
                                    <v-icon>mdi-calendar</v-icon>
                                </template>
                                <template v-slot:timeIcon>
                                    <v-icon>mdi-clock</v-icon>
                                </template>
                            </v-datetime-picker>
                        </div>
                        <div class="mr-2">
                            <v-btn 
                                dark 
                                small
                                color="purple darken-3" 
                                :loading="loading"
                                @click.prevent.stop="fetch"
                            >
                                <v-icon left>mdi-filter</v-icon>
                                Фильтр
                            </v-btn>
                            <v-btn 
                                dark 
                                small
                                color="orange darken-3" 
                                class="ml-2"
                                @click.prevent.stop="reset"
                            >
                                <v-icon left>mdi-refresh</v-icon>
                                Сброс
                            </v-btn>
                        </div>
                    </div>

                    <v-divider />

                    <v-simple-table>
                        <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    URL
                                </th>
                                <th class="text-left">
                                    Общее кол-во посещений
                                </th>
                                <th class="text-left">
                                    Кол-во уникальных пользователей
                                </th>
                                <th class="text-left">
                                    Кол-во уник. пользов. с 2 и менее посещений 
                                </th>
                                <th class="text-left">
                                    Кол-во уник. пользов. с 3 и более посещений 
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="item in items"
                                :key="item.url"
                            >
                                <td>{{ item.url }}</td>
                                <td>{{ item.total_count }}</td>
                                <td>{{ item.uniq_count }}</td>
                                <td>{{ item.users_with_less_then_3_loads || 0 }}</td>
                                <td>{{ item.users_with_more_then_2_loads || 0 }}</td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>

            <div v-else class="mx-auto">
                <v-alert type="error">
                    {{ errors || 'Произошла ошибка на сервере. Обратитесь за помощью к администратору.' }}
                </v-alert>

                <div
                    v-if="allowEdit"
                    class="w-100 d-flex justify-center align-center"
                >
                    <v-btn class="mx-auto" @click="resetError">
                        <v-icon class="mr-1">mdi-keyboard-backspace</v-icon>
                        Отмена
                    </v-btn>
                </div>
            </div>
        </v-container>
    </div>
    <!-- Loading icon -->
    <div
        v-else
        class="
            wv-100
            vh-100
            fill-height
            d-flex
            flex-row
            justify-center
            align-center
        "
    >
        <v-progress-circular
            :size="70"
            :width="7"
            color="purple darken-3"
            indeterminate
        />
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from 'moment';
import SubpageTopBar from '@/components/template/SubpageTopBar';

const todayYMD = moment().format('YYYY-MM-DD');
const todayFrom = `${todayYMD} 00:00`
const todayTo = `${todayYMD} 23:59`

export default {
    name: "Metrics",
    components: {
        SubpageTopBar
    },
    computed: {
        ...mapGetters("user", ["allowEdit"])
    },
    data () {
        return {
            ready: false,
            loading: false,
            filter: {
                from: todayFrom,
                to: todayTo
            },
            items: [],
            errors: null,
            totalUniqVisitorCount: 0
        };
    },
    methods: {
        async fetch() {
            this.loading = true;
            const { data } = await this.$store.dispatch('metrics/list', {
                filter: JSON.stringify([
                    'AND', 
                    ['>=', 'created_at', moment(this.filter.from).format('X')],
                    ['<=', 'created_at', moment(this.filter.to).format('X')]
                ])
            });
            this.items = data.items;
            this.loading = false;
        },
        reset() {
            this.$set(this, 'filter', {
                from: todayFrom,
                to: todayTo
            });
            this.fetch();
        },
        resetError () {
            this.errors = null;
        }
    },
    async created () {
        if (!this.allowEdit) {
            this.errors = 'У вас недостаточно прав для доступа к этому разделу.';
        }
        // Fetch data 
        await this.fetch();
        // Ready
        this.ready = true;
    }
};
</script>